import React, { useEffect, useState } from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import axios from 'axios'
import ReactGA from 'react-ga4'
import PhoneInput from 'react-phone-input-2'
import { useNavigate, useParams } from 'react-router-dom'
import Footer from 'components/Footer'
import Header from 'components/Header'
import TextField from 'components/shared/textField'
import { SetSkipCongratsOffers } from 'redux/Offers/nonPersist'
import {
  SetIsMoreThanOneStudioOffers,
  SetPaymentDoneOfferFlow,
  SetPaymentPlansOfferFlow,
  SetPurchasedPlanOfferFlow,
  SetSelectedSlotOfferFlow,
  SetSelectedStudioOffers,
  SetSlotBookedAlreadyOfferFlow,
  SetSlotNotFoundOfferFlow,
  SetStudiosOffers,
  SetTimeLeftHomeOfferFlow,
  SetTimeLeftOfferFlow,
  SetVisitCountOfferFlow,
} from 'redux/Offers/persist'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { StatusEnum } from 'redux/types'
import { EMAIL_REGEX } from 'regexConstants'
import { decodeToken } from 'utils/decodeToken'
import { formatPhoneNumber } from 'utils/formatPhoneNumber'
import styles from './Signup.module.scss'
import style from './VerifyOtp.module.scss'
import 'react-phone-input-2/lib/material.css'
import { AppConstants, Channel, LeadStage } from '../../../AppConstants/AppConstants'
import useAuth from '../../../hooks/useAuth'

interface InitialValues {
  firstname: string
  // lastname: string
  // email: string
  // gender: string
  // phone: string
}

const LoginOffers: React.FC = () => {
  const initialValues: InitialValues = {
    firstname: '',
    // lastname: '',
    // email: '',
    // gender: '',
    // phone: '',
  }

  const styless: any = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 260,
    height: 170,
    overflow: 'hidden',
    backgroundColor: '#050624',
    color: 'white',
    borderRadius: '8px',
    p: 4,
  }
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  // const [data, setData] = useState(initialValues)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [formattedPhoneNum, setFormattedPhoneNum] = useState('')
  // const [dobValue, setDobValue] = useState<Date | any>(null)
  const [OTPScreen, setOTPScreen] = useState(false)
  const [otpError, setOtpError] = useState(false)
  const [disableVerifyBtn, setDisableVerifyBtn] = useState(true)
  const [disableBookMyself, setDisableBookMyself] = useState(true)
  const [disableCallMeNow, setDisableCallMeNow] = useState(true)
  const [isCallInProgress, setIsCallInProgress] = useState(false)
  const [code, setCode] = useState('')
  const [firstNameError, setFirstNameError] = useState(false)
  const [phoneError, setPhoneError] = useState(false)
  // const [lastNameError, setLastNameError] = useState(false)
  const [modal, setModal] = useState(false)
  const [verifyStatus, setVerifyStatus] = useState<StatusEnum>(StatusEnum.Idle)
  // const [dobError, setDobError] = useState(false)
  // const [genderError, setGenderError] = useState(false)
  // const [emailError, setEmailError] = useState(false)
  const [errorType, setErrorType] = useState('')
  // const [wrongNumber, setWrongNumber] = useState(false)
  const [userId, setUserId] = useState('')
  // const { currentRole } = useAuth()
  const isMoreThanOneStudio = useAppSelector((state) => state.isMoreThanOneStudioOffers.isMoreThanOneStudioOffers)
  const firstname = localStorage.getItem(AppConstants.USER_FIRST_NAME)

  async function getPlans() {
    try {
      const response: any = await axios
        .get(`${AppConstants.API_URL}/payment-plan?campaign=${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
          },
        })
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
      if (response && response?.data && response?.data?.data) {
        console.log(response?.data?.data, 'plans')
        dispatch(SetPaymentPlansOfferFlow(response?.data?.data))
        return response?.data?.data
      } else if (response?.data?.statusCode === 401) {
        // navigate('/login')
        console.log('error')
        //call reset login
      }
    } catch (error) {
      console.error(error)
    }
  }

  // const onContinue = async () => {
  //   let auth = false

  //   if (!firstname) {
  //     setFirstNameError(true)
  //     setErrorType('fieldError')
  //     auth = true
  //   } else {
  //     setFirstNameError(false)
  //   }

  //   if (data.phone.replace(/-/g, '').length < 10) {
  //     setPhoneError(true)
  //     setErrorType('Incorrect-phoneFormat')
  //     auth = true
  //   } else {
  //     setPhoneError(false)
  //   }

  //   if (!auth) {
  //     let response: any = await axios
  //       .post(
  //         AppConstants.API_URL + '/referral/lead/signup',
  //         {
  //           ...data,
  //           phone: '+1' + data.phone.replace(/-/g, ''),
  //           referralCode: id,
  //         },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${localStorage.getItem('userToken')}`,
  //             'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
  //           },
  //         },
  //       )
  //       .then((res) => res)
  //       .catch((err) => err.response)

  //     console.log(response, 'jjjjjjjjres')

  //     if (response && response.data && response.data.code === 'success') {
  //       setErrorType('')
  //       setOTPScreen(true)
  //       setUserId(response.data.data.id)

  //       if (id === 'facebook') {
  //         //@ts-ignore
  //         fbq('track', 'Lead')
  //       } else {
  //         ReactGA.event('conversion', {
  //           send_to: 'AW-11337579902/m3hPCJKL4LgZEP76lp4q',
  //         })
  //       }
  //     } else if (response && response.data && response.data.code === 'user_already_exists') {
  //       setErrorType('userExists')
  //     } else if (response && response.data && response.data && response.data.code === 'email exists') {
  //       setErrorType('emailExists')
  //     } else if (response && response.data && response.data.code === 'phone_number_already_exists') {
  //       setErrorType('mobileExists')
  //     } else if (response && response.data && response.data.code >= 400 && response.data.code <= 599) {
  //       setErrorType('Error_Occurred')
  //     } else if (response && response.data && response.data.statusCode >= 400 && response.data.statusCode <= 599) {
  //       setErrorType('Error_Occurred')
  //     } else {
  //       setErrorType('unknown')
  //     }
  //   }
  // }

  const sendOtp = async () => {
    if (phoneNumber.length >= 4 && firstname?.length !== 0) {
      setDisableVerifyBtn(true)
      const response: any = await axios
        .post(AppConstants.API_URL + '/referral/lead/register/signup', {
          firstname: firstname,
          phone: '+' + phoneNumber.replace(/-/g, ''),
          referralCode: id,
        })
        .then((res) => res)
        .catch((err) => err.response)
      if (
        response &&
        response?.data?.code === 'success' &&
        response?.data?.message === 'Verification code sent successfully'
      ) {
        setUserId(response?.data?.data?.id)
        setOTPScreen(true)
      } else if (response && response?.data?.message === 'Phone number is not found') {
        setErrorType('Not_User')
      } else if (response && response.data && response.data.code >= 400 && response.data.code <= 599) {
        setErrorType('Error_Occurred')
      } else if (response && response.data && response.data.statusCode >= 400 && response.data.statusCode <= 599) {
        setErrorType('Error_Occurred')
      }
    } else {
      if (firstname?.length === 0) {
        setErrorType('firstNameEmpty')
        setDisableBookMyself(true)
      }
      if (phoneNumber.length < 4) {
        setErrorType('formatInvalid')
      }
    }
  }

  const onVerify = async () => {
    setDisableVerifyBtn(true)
    if (code === '') {
      setOtpError(true)
      setErrorType('Empty')
    } else {
      try {
        const response: any = await axios
          .post(AppConstants.API_URL + '/referral/lead/register/verify-otp', {
            phone: '+' + phoneNumber.replace(/-/g, ''),
            code: code,
            signupThrough: 'web',
            firstname: firstname,
            referralCode: id,
            id: userId,
          })
          .then((res) => res)
          .catch((err) => err.response)

        if (response && response.data && response.data.code === 'success') {
          localStorage.setItem(AppConstants.ACCESS_TOKEN, response?.data?.data?.accessToken)
          let localToken = response.data?.data?.accessToken
          let tokenParts = localToken.split('.')
          let tokenPayload = tokenParts[1]
          let tokenPayloadStr = atob(tokenPayload)
          let decodedToken: any = JSON.parse(tokenPayloadStr)
          let tempCurrentRole: any = ''
          if (decodedToken) {
            if (decodedToken.roles && decodedToken.roles.length === 1) {
              tempCurrentRole = decodedToken.roles[0]
            }
          } else {
            tempCurrentRole = null
          }
          localStorage.setItem(AppConstants.CURRENTROLE_TOKEN, tempCurrentRole)
          // if (tempCurrentRole === 'PATIENT') {
          //   navigate('/add-photo')
          // } else {
          //   navigate('/signup')
          // }
          //@ts-ignore

          if (id === 'facebook') {
            //@ts-ignore
            fbq('track', 'SubmitApplication')
          } else {
            ReactGA.event('conversion', {
              send_to: process.env.REACT_APP_GA_CONVERSION_ID || 'AW-11337579902/m3hPCJKL4LgZEP76lp4q',
            })
          }

          setVerifyStatus(StatusEnum.Success)
        } else if (response && response.data && response.data.code === 'user_already_exists') {
          // setOtpError(true)
          setModal(true)
          // setErrorType('userExists')
          setDisableVerifyBtn(false)
        } else if (response && response.data && response.data.code === 'invalid_value') {
          setOtpError(true)
          setErrorType('Invalid')
          setDisableVerifyBtn(false)
        } else if (response && response.data && response.data.code === 'code_expired') {
          setOtpError(true)
          setErrorType('Code_Expired')
          setDisableVerifyBtn(false)
        } else if (response && response.data && response.data.code === 'user_not_found') {
          setErrorType('Not_User')
          setDisableVerifyBtn(false)
        } else if (response && response.data && response.data.statusCode >= 400 && response.data.statusCode <= 599) {
          setErrorType('Error_Occurred')
          setDisableVerifyBtn(false)
        }
      } catch (error) {
        console.error('An error occurred:', error)
      }
    }
  }

  // const callMeNow = async () => {
  //   // dispatch(SetRegularFirstname(firstName))
  //   try {
  //     if (data.phone.length >= 4) {
  //       const response: any = await axios
  //         .post(AppConstants.API_URL + '/bland/generate-bland-call', {
  //           firstname: firstname,
  //           phone: '+' + data.phone.replace(/-/g, ''),
  //         })
  //         .then((res) => res)
  //         .catch((err) => err.response)

  //       if (response && response?.data?.code === 'success' && response?.data?.data?.status === 'success') {
  //         navigate('/bland/welcome')
  //       } else if (response && response?.data?.code === 'success' && response?.data?.data?.status === 'error') {
  //         console.log('Failed to trigger call', response?.data?.data?.message)
  //       }
  //     }
  //   } catch (error) {
  //     console.log('could not connect call.')
  //   }
  // }

  const handleKeyDown = (e: any, nextFieldName: any) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      const nextField = e.target.form.elements[nextFieldName]
      if (nextField) {
        nextField.focus()
      }
    }
  }

  async function getProfile() {
    try {
      const response: any = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/me`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
        },
      })

      dispatch(
        SetVisitCountOfferFlow({
          onDemandCount: response?.data?.data?.onDemandCount ?? null,
          subscriptionCount: response?.data?.data?.subscriptionCount ?? null,
          previousVisits: response?.data?.data?.previousVisits ?? null,
        }),
      )
      dispatch(SetPaymentDoneOfferFlow(response?.data?.data?.initialPayment))
      dispatch(SetTimeLeftHomeOfferFlow(null))
      // if (
      //   response &&
      //   response?.data.code === 'success' &&
      //   response?.data?.data?.dob === null &&
      //   response?.data?.data?.previousVisits === false
      // ) {
      //   getPlans()
      //   navigate(`/offers/${id}/dob`)
      // }

      // if (response && response.data && response.data.data) {
      //   if (response.data.data.previousVisits === true) {
      //     if (!response.data.data.lastname || !response.data.data.email || !response.data.data.dob) {
      //       dispatch(SetSkipCongratsOffers(true))
      //       navigate(`/offers/${id}/signup`)
      //       return
      //     }
      //   }
      // }

      if (response && response?.data.code === 'success' && response?.data?.data?.previousVisits === false) {
        const plan = await getPlans()
          .then((res) => {
            return res
          })
          .catch((err) => {
            return err?.response
          })
        postLeadStage(plan && plan?.length > 0 ? (plan[0]?.amount > 0 ? Channel.marketing : Channel.free) : null)
        getAllStudios()
      } else if (response && response?.data.code === 'success' && response?.data?.data?.previousVisits === true) {
        setModal(true)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getAllStudios = async () => {
    const response: any = await axios
      .get(AppConstants.API_URL + '/clinic', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
        },
      })
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err.response
      })

    if (response?.data?.code === 'success') {
      dispatch(SetStudiosOffers(response?.data.data))
      if (response?.data.data) {
        dispatch(SetIsMoreThanOneStudioOffers(response?.data.data.length > 1 ? true : false))
        if (response?.data.data.length > 1) {
          navigate(`/offers/${id}/select-studio`)
        } else {
          navigate(`/offers/${id}/physical-visit`)
        }
      }
    } else {
      return false
    }
  }

  const postLeadStage = async (channel: string | null) => {
    await axios
      .post(
        AppConstants.API_URL + '/lead/stage/update',
        { stage: LeadStage.otp, channel: channel },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
          },
        },
      )
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err?.response
      })
  }

  useEffect(() => {
    if (verifyStatus === StatusEnum.Success) {
      let localToken = localStorage.getItem(AppConstants.ACCESS_TOKEN)
      if (localToken) {
        const isPatient = decodeToken(localToken)
        if (isPatient && isPatient === 'PATIENT') {
          getProfile()
        } else {
          navigate(`/offers/${id}/download`)
        }
      }
    }
  }, [verifyStatus])

  useEffect(() => {
    if (phoneNumber.length >= 4 && firstname && firstname?.length > 0) {
      setDisableBookMyself(false)
    }
  }, [OTPScreen])

  useEffect(() => {
    if (id === 'google') {
      ReactGA.event('page_view', {
        page_title: 'Offers|Login',
        page_location: `/offers/${id}/login`,
      })
    }
  }, [id])

  useEffect(() => {
    if (id === 'facebook') {
      //@ts-ignore
      fbq('track', 'ViewContent')
    }
  }, [id])

  useEffect(() => {
    dispatch(SetVisitCountOfferFlow(null))
    dispatch(SetSelectedSlotOfferFlow(null))
    dispatch(SetPaymentPlansOfferFlow([]))
    dispatch(SetPurchasedPlanOfferFlow(null))
    dispatch(SetPaymentDoneOfferFlow(false))
    dispatch(SetSlotBookedAlreadyOfferFlow(false))
    dispatch(SetSlotNotFoundOfferFlow(false))
    dispatch(SetTimeLeftOfferFlow(null))
    dispatch(SetSelectedStudioOffers(null))
    dispatch(SetStudiosOffers([]))
    // dispatch(SetIsMoreThanOneStudioOffers(false))
  }, [])

  if (!OTPScreen) {
    return (
      <div className={styles.container}>
        <Header
          headerText={''}
          showBackButton={true}
          onBack={() => {
            navigate(`/offers/${id}/name`)
          }}
        ></Header>
        <div className={styles.content}>
          <div className={styles.textContainer}>
            <span>Nice to meet you, </span>
            <span style={{ textTransform: 'capitalize' }}>{firstname}.</span>
            <br></br>
            <span>What’s the best number to </span>
            <br></br>
            <span>reach you at?</span>
          </div>
          <div className={styles.formContainer}>
            <form
              onSubmit={(e) => {
                e.preventDefault()
              }}
            >
              <Grid container>
                {/* <Grid item xs={12}>
                  <TextField
                    autoFocus
                    sx={
                      firstNameError
                        ? {
                            backgroundImage: "url('images/error.svg')",
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'right 15px center',
                          }
                        : {
                            '& .MuiInputBase-input': {
                              textTransform: 'capitalize',
                            },
                          }
                    }
                    style={{ marginTop: '5px' }}
                    placeholder="Enter first name"
                    label="First Name"
                    name="firstname"
                    value={firstname}
                    error={firstNameError}
                    onChange={(e) => {
                      setData({ ...data, firstname: e.target.value })
                      if (firstNameError) {
                        setFirstNameError(false)
                      }
                      if (errorType !== '') {
                        setErrorType('')
                      }

                      if (e.target.value.length === 0 || phoneNumber.length < 4) {
                        if (!disableBookMyself) {
                          setDisableBookMyself(true)
                        }
                        if (!disableCallMeNow) {
                          setDisableCallMeNow(true)
                        }
                      } else {
                        setFirstNameError(false)
                        if (disableBookMyself) {
                          setDisableBookMyself(false)
                        }
                        if (disableCallMeNow) {
                          setDisableCallMeNow(false)
                        }
                      }
                    }}
                    onKeyDown={(e) => handleKeyDown(e, 'phone')}
                  />
                </Grid> */}

                <Grid item xs={12}>
                  {/* <TextField
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                      maxLength: 12,
                    }}
                    sx={
                      phoneError
                        ? {
                            backgroundImage: "url('/images/error.svg')",
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'right 15px center',
                            textTransform: 'capitalize',
                          }
                        : {
                            '& .MuiInputBase-input': {
                              textTransform: 'capitalize',
                            },
                          }
                    }
                    placeholder="Enter Phone Number"
                    value={data.phone}
                    label="Phone"
                    name="phone"
                    error={phoneError}
                    onChange={(e) => {
                      const formattedValue = e.target.value
                        .replace(/\D/g, '')
                        .slice(0, 10)
                        .replace(/(\d{3})(\d{1,3})?(\d{0,4})?/, (_, first, second, third) => {
                          let result = first
                          if (second) result += '-' + second
                          if (third) result += '-' + third
                          return result
                        })
                      setData({ ...data, phone: formattedValue })
                      if (phoneError) {
                        setPhoneError(false)
                      }
                      if (errorType !== '') {
                        setErrorType('')
                      }
                      if (e.target.value.replace(/-/g, '').length < 10 || firstname.length === 0) {
                        // setPhoneError(true)
                        if (!disableBookMyself) {
                          setDisableBookMyself(true)
                        }
                        if (!disableCallMeNow) {
                          setDisableCallMeNow(true)
                        }
                        // setErrorType('Incorrect-phoneFormat')
                      } else {
                        // setPhoneError(false)
                        if (disableBookMyself) {
                          setDisableBookMyself(false)
                        }
                        if (disableCallMeNow) {
                          setDisableCallMeNow(false)
                        }
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        disableBookMyself ? undefined : sendOtp()
                      }
                    }}
                  /> */}
                  <PhoneInput
                    inputProps={{
                      name: 'phone',
                      autoFocus: true,
                    }}
                    // enableSearch
                    // searchStyle={{ width: 250, height: 20, border: 'none' }}
                    country={'us'}
                    value={phoneNumber}
                    preferredCountries={['us', 'ca', 'gb', 'sa', 'ae']}
                    priority={['us', 'ca', 'gb', 'sa', 'ae']}
                    preserveOrder={['preferredCountries']}
                    // onlyCountries={['us', 'in', 'ca', 'uk', 'sa', 'ae']}
                    containerClass={styles.phoneCtn}
                    dropdownClass={styles.dropdown}
                    specialLabel=""
                    inputClass={styles.input}
                    buttonClass={styles.countryBtn}
                    countryCodeEditable={false}
                    // dropdownStyle={{ width: '360px' }}
                    // masks={{
                    //   us: '...-...-....',
                    //   in: '...-...-....',
                    //   ca: '...-...-....',
                    //   uk: '...-...-....',
                    //   sa: '...-...-...-...',
                    //   ae: '...-...-...-...',
                    // }}
                    // priority={{ us: 1, in: 0, ca: 0, uk: 0, sa: 0, ae: 0 }}
                    onChange={(value, e: any) => {
                      setPhoneNumber(value)
                      setErrorType('')
                      const formatVal = value.slice(e.dialCode.length)
                      setFormattedPhoneNum(formatVal)
                      if (formatVal.length < 4 || firstname?.length === 0) {
                        setDisableBookMyself(true)
                      } else {
                        setDisableBookMyself(false)
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        if (!disableBookMyself) {
                          sendOtp()
                        }
                      }
                    }}
                    inputStyle={
                      errorType === 'fieldEmpty' || errorType === 'formatInvalid' || errorType === 'userNot'
                        ? {
                            backgroundImage: "url('images/error.svg')",
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'right 15px center',
                            textTransform: 'capitalize',
                            border: '2px solid #F24343',
                          }
                        : {}
                    }
                  />
                </Grid>
                <div className={styles.phoneNumberConsent}>
                  100% Secure. By Submitting, you agree to KIRO’s Terms of Use and Privacy Policy. You consent to
                  receive text messages from us at the mobile number provided to book your appointment and receive
                  reminders. Message frequency depends on your activity. Reply STOP to opt-out.
                </div>
                <Grid item xs={12} sx={{ marginTop: '150px', position: 'absolute', width: '95%' }}>
                  {errorType && (
                    <div className={styles.errorContainer}>
                      <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
                        {errorType === 'fieldError' && `Please complete all fields above`}
                        {errorType === 'userExists' && `User already exists`}
                        {errorType === 'mobileExists' && `Mobile number already exists`}
                        {errorType === 'unknown' && `Unknown error`}
                        {errorType === 'Incorrect-phoneFormat' && `Invalid Mobile Number`}
                        {errorType === 'Error_Occurred' && `Oops! something went wrong`}
                        {errorType === 'under_18' && `You must be 18 or older to sign up`}
                      </Typography>
                      <div
                        style={{
                          display: 'flex',
                          flex: '1',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          paddingRight: '12px',
                        }}
                      >
                        <img
                          onClick={() => {
                            setErrorType('')
                          }}
                          style={{ cursor: 'pointer' }}
                          src={'/images/wrong.svg'}
                        ></img>
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>
            </form>
            {/* <div className={styles.buttonContainer}>
              <div className={styles.scheduleText}>How would you like to schedule your appointment?</div>
              <Button
                id="send-otp"
                type="submit"
                // startIcon={<img src="/images/bookMyself.svg"></img>}
                sx={disableBookMyself ? { opacity: '0.5' } : {}}
                children={'Continue'}
                onClick={
                  disableBookMyself
                    ? undefined
                    : () => {
                        sendOtp()
                      }
                }
              ></Button>
              <Button
                startIcon={!isCallInProgress ? <img src="/images/callMeNow.svg"></img> : ''}
                id="call-me-now"
                type="submit"
                sx={disableCallMeNow ? { opacity: '0.5' } : {}}
                style={{ marginTop: '8px', color: '#007f7c', background: 'transparent', border: '1px solid #007f7c' }}
                onClick={
                  disableCallMeNow
                    ? undefined
                    : () => {
                        setDisableCallMeNow(true)
                        setIsCallInProgress(true)
                        callMeNow()
                      }
                }
              >
                {!isCallInProgress && 'Call Me Now'}
                {isCallInProgress && (
                  <CircularProgress
                    size={20}
                    sx={{
                      color: 'white',
                      width: '14px',
                      height: '14px',
                      left: '90%',
                    }}
                  />
                )}
              </Button>
            </div> */}
          </div>
        </div>
        <Footer
          buttonText={'Continue'}
          isLoading={false}
          shouldShowFour={true}
          shouldShowFive={true}
          shouldShowSix={isMoreThanOneStudio}
          trackerIndex={2}
          isDisabled={disableBookMyself}
          buttonId={'sign-in-button'}
          buttonType={'submit'}
          onButtonClick={() => {
            disableBookMyself ? undefined : sendOtp()
          }}
        ></Footer>
      </div>
    )
  } else {
    return (
      <div className={style.container}>
        <Header
          headerText=""
          showBackButton={modal === true ? false : true}
          onBack={() => {
            setCode('')
            setOTPScreen(false)
            setErrorType('')
            setOtpError(false)
            setPhoneNumber(phoneNumber)
            setDisableVerifyBtn(true)
          }}
          hideIcon={false}
        />
        <div className={style.verifyOtpContainer}>
          <p className={style.codeText}>
            Please enter the 6 digit code <br></br> sent to&nbsp;
            <b className={style.boldText}>{formatPhoneNumber(formattedPhoneNum)}</b> <br></br> through SMS.
          </p>
          <form
            onSubmit={(e) => {
              e.preventDefault()
            }}
          >
            <Grid container spacing={2} sx={{ marginBottom: '12px', marginTop: '30px' }}>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    maxLength: 6,
                  }}
                  sx={
                    otpError
                      ? {
                          backgroundImage: "url('images/error.svg')",
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'right 15px center',
                          textTransform: 'capitalize',
                        }
                      : {}
                  }
                  value={code}
                  placeholder="Enter code"
                  label="Verification Code"
                  name="code"
                  // type="number"
                  error={otpError}
                  onChange={(e) => {
                    setCode(e.target.value)
                    setOtpError(false)
                    if (e.target.value.length >= 6) {
                      setDisableVerifyBtn(false)
                    } else {
                      setDisableVerifyBtn(true)
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      disableVerifyBtn ? undefined : onVerify()
                    }
                  }}
                />
              </Grid>
            </Grid>
          </form>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
            <div
              className={style.signUpText}
              onClick={() => {
                sendOtp()
              }}
            >
              Resend OTP{'>'}
            </div>
            <div
              className={style.signUpText}
              onClick={() => {
                setOTPScreen(false)
                setCode('')
                setDisableVerifyBtn(true)
                if (otpError || errorType !== '') {
                  setErrorType('')
                  setOtpError(false)
                }
              }}
            >
              Wrong Number?
            </div>
          </div>

          <Grid item xs={12}>
            {otpError && (
              <div className={style.errorContainer}>
                <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
                  {errorType === 'Empty' && `Please enter passcode`}
                  {errorType === 'userExists' && `User already exists`}
                  {errorType === 'Invalid' && `Invalid Code`}
                  {errorType === 'Code_Expired' && `Oops! code expired, Click Resend`}
                  {errorType === 'Error_Occurred' && `Oops! something went wrong`}
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    flex: '1',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    paddingRight: '12px',
                  }}
                >
                  <img
                    onClick={() => {
                      setOtpError(false)
                    }}
                    style={{ cursor: 'pointer' }}
                    src={'/images/wrong.svg'}
                  ></img>
                </div>
              </div>
            )}
          </Grid>
          {modal && (
            <Dialog onClose={() => {}} open={modal} PaperProps={{ style: styless }} className={style.modalPopUp}>
              <DialogTitle style={{ fontSize: '14.5px', marginTop: '0rem', marginLeft: '0.5rem' }}>
                You’ve previously signed up. Please download the KIRO mobile app to continue.
              </DialogTitle>
              <Button
                color="primary"
                style={{
                  width: 'fit-content',
                  marginBottom: '1rem',
                  height: '2rem',
                  padding: '0px 8px 0px 8px',
                  fontSize: '12px',
                }}
                onClick={() => {
                  navigate(`/offers/${id}/download`)
                }}
              >
                Ok
              </Button>
            </Dialog>
          )}
        </div>
        <Footer
          buttonText={'Verify'}
          isLoading={false}
          trackerIndex={2}
          shouldShowFour={true}
          shouldShowFive={true}
          shouldShowSix={isMoreThanOneStudio}
          isDisabled={disableVerifyBtn}
          buttonId={'sign-in-button'}
          buttonType={'submit'}
          onButtonClick={() => {
            disableVerifyBtn ? undefined : onVerify()
          }}
        ></Footer>
      </div>
    )
  }
}

export default LoginOffers
